<template>
  <!-- 项目布局页 -->
  <div class="common-layout" id="commonLayout">
    <el-container class="layout-container" direction="vertical">
      <layout-header></layout-header>
      <el-main class="layout-main">
        <router-view />
      </el-main>
      <!-- 添加实体抽屉 -->
      <create-entity-drawer ref="createEntityDrawerRef"></create-entity-drawer>
    </el-container>
  </div>
</template>

<script setup>
import LayoutHeader from "../components/common/layout/Header.vue";
import CreateEntityDrawer from "@/components/graph/CreateEntityDrawer";
import { ref, provide } from "vue";
const createEntityDrawerRef = ref(null);

// 打开抽屉
const handleOpenDrawer = () => {
  createEntityDrawerRef.value.openDrawer();
};

provide("handleOpenDrawer", handleOpenDrawer);
</script>

<style lang="scss" scoped>
.common-layout {
  .el-main {
    padding: 0;
  }
}
</style>
