<template>
  <div class="kyy">
    <div class="text" v-showTip>
      <el-tooltip :content="text1" placement="top-start">
        <span class="value">
          <span class="ellipse-text">{{ text1 }}</span>
        </span>
      </el-tooltip>
    </div>
    <div class="text" v-showTip>
      <el-tooltip :content="text2" placement="top-start">
        <span class="value">
          <span class="ellipse-text">{{ text2 }}</span>
        </span>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup>
const text1 = "阿空加";
const text2 = "阿空加阿空加阿空加阿空加阿空加阿空加阿空加阿空加";
</script>

<style lang="scss" scoped>
.text {
  width: 100px;
  text-align: left;
}
.value {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipse-text {
} // 目前什么内容都没有，方便标识而已
</style>
