<template>
  <!-- 公共布局-头部 -->
  <el-header height="64" class="layout-header">
    <div class="header-left">
      <!-- logo -->
      <div class="logo-wrapper">
        <img
          src="@/assets/images/common/logo.png"
          alt=""
          class="logo"
          @click="goSearch()"
        />
        <h1 class="title">知识图谱</h1>
      </div>
      <!-- 搜索框 -->

      <div
        class="search-wrapper"
        v-if="router.currentRoute.value.fullPath !== '/search'"
      >
        <el-input
          ref="searchInputRef"
          v-model.trim="state.keywords"
          placeholder="请输入内容"
          @keyup="searchEntityByKeywords"
          @keyup.enter="searchByKeywords()"
          size="small"
          clearable
          @clear="state.cardVisible = false"
        >
          <template #append>
            <el-button icon="Search" @click="searchByKeywords()" />
          </template>
        </el-input>
        <!-- 搜索推荐列表 -->
        <el-card class="box-card" style="width: 790px" v-if="state.cardVisible">
          <el-scrollbar :height="scrollbarHeight()">
            <div
              class="search-item"
              v-for="(entityItem, entityItemKey) in state.searchCardList"
              :key="entityItemKey"
              @click="selectEntity(entityItem.name, entityItem.entityId)"
            >
              <div class="title" style="text-align: left">
                <h3>{{ entityItem.name }}</h3>
                <span>{{ entityItem.type }}</span>
              </div>
              <p class="description kn-ellipsis">
                {{ entityItem.description }}
              </p>
            </div>
            <!-- 提示文字 -->
            <div v-if="state.searchCardList.length == 0" class="notice">
              没有匹配到对应实体
            </div>
          </el-scrollbar>
        </el-card>
      </div>
    </div>
    <div class="header-center"></div>
    <div class="header-right">
      <span style="position: relative; top: 1px">当前图谱：</span>
      <el-select
        v-model="tempValue"
        size="small"
        style="positon: relative; width: 150px; top: 1px"
        @change="handleChangeTempValue"
      >
        <el-option value="1" label="乳腺癌"></el-option>
        <el-option value="2" label="癌因性疲乏"></el-option>
        <el-option value="3" label="乳腺癌淋巴水肿预防与干预"></el-option>
      </el-select>
      <!-- 产品 -->
      <el-popover
        :teleported="false"
        placement="bottom"
        :width="240"
        trigger="hover"
        popper-class="product-popover"
      >
        <template #reference>
          <el-button circle class="operate-btn">
            <span class="iconfont icon-application"></span>
          </el-button>
        </template>
        <div class="product-wrapper">
          <div
            class="product-block"
            v-for="(item, index) in productList"
            :key="index"
          >
            <div
              class="product"
              v-for="(itm, idx) in item"
              :key="idx"
              @click="goProduct(itm.path)"
            >
              <img :src="itm.img" alt="" />
              <div class="text">{{ itm.text }}</div>
            </div>
          </div>
          <!-- <div class="btn-box"> -->
          <el-button size="small" @click="goAllProduct" class="more-product"
            >更多锐研产品和应用</el-button
          >
        </div>
        <!-- </div> -->
      </el-popover>

      <!-- 帮助 -->
      <!-- <el-button circle @click="goHelp" class="operate-btn">
        <span class="iconfont icon-help"></span>
      </el-button> -->
      <!-- 反馈 -->
      <!-- <el-button circle @click="goFeedBack" class="operate-btn">
        <span class="iconfont icon-feedback"></span>
      </el-button> -->
      <!-- 登陆 -->
      <header-login></header-login>
    </div>
  </el-header>
</template>

<script setup>
import { httpPost } from "@/api/httpService";
import { reactive, ref, watch, inject, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import HeaderLogin from "./HeaderLogin.vue";
import { remindErrorMessage } from "@/utils/utils";

const router = useRouter();
const route = useRoute();
const reload = inject("reload");
const handleOpenDrawer = inject("handleOpenDrawer");
const searchInputRef = ref(null);

const state = reactive({
  cardVisible: false, //是否展示搜索推荐卡片
  keywords: route.query.keywords, //搜索关键字
  entityId: "", // 搜索实体的id
  searchCardList: [], //推荐卡片数据
});
const productList = [
  [
    {
      img: require("@/assets/images/all-product/新闻.png"),
      text: "新闻",
      path: "/news",
    },
    {
      img: require("@/assets/images/all-product/政策.png"),
      text: "政策",
      path: "/policy",
    },
    {
      img: require("@/assets/images/all-product/法律.png"),
      text: "法律",
      path: "/law",
    },
    {
      img: require("@/assets/images/all-product/文书.png"),
      text: "文书",
      path: "/judge",
    },
    {
      img: require("@/assets/images/all-product/专利.png"),
      text: "专利",
      path: "/patent",
    },
    {
      img: require("@/assets/images/all-product/数集.png"),
      text: "数集",
      path: "/dataset",
    },
    {
      img: require("@/assets/images/all-product/项目.png"),
      text: "项目",
      path: "/project",
    },
    {
      img: require("@/assets/images/all-product/循证.png"),
      text: "循证",
      path: "/evidence",
    },
    {
      img: require("@/assets/images/all-product/论文.png"),
      text: "论文",
      path: "/papers",
    },
    {
      img: require("@/assets/images/all-product/全球企业.png"),
      text: "全球企业",
      path: "https://www.ringdata.com/hoovers",
    },
    {
      img: require("@/assets/images/all-product/开放数据.png"),
      text: "开放数据",
      path: "https://open.ringdata.com/",
    },
    {
      img: require("@/assets/images/all-product/数据定制.png"),
      text: "数据定制",
      path: "https://www.ringdata.com/service",
    },
  ],
  [
    {
      img: require("@/assets/images/all-product/趋势.png"),
      text: "趋势",
      path: "/trends/news",
    },
    {
      img: require("@/assets/images/all-product/相关.png"),
      text: "相关",
      path: "/corelate",
    },
    // {
    //   img: require("../assets/images/all-product/因果.png"),
    //   text: "因果",
    //   path: ""
    // },
    {
      img: require("@/assets/images/all-product/图谱.png"),
      text: "图谱",
      path: "https://www.ringdata.com/knowledge-graph",
    },
  ],
  [
    {
      img: require("@/assets/images/all-product/云调查.png"),
      text: "云调查",
      path: "https://dc.ringdata.com/",
    },
    {
      img: require("@/assets/images/all-product/云访谈.png"),
      text: "云访谈",
      path: "https://ft.ringdata.com/",
    },
    {
      img: require("@/assets/images/all-product/云采集.png"),
      text: "云采集",
      path: "https://cj.ringdata.com/",
    },
    {
      img: require("@/assets/images/all-product/云统计.png"),
      text: "云统计",
      path: "https://tj.ringdata.com/",
    },
    {
      img: require("@/assets/images/all-product/云文析.png"),
      text: "云文析",
      path: "https://wx.ringdata.com/",
    },
    {
      img: require("@/assets/images/all-product/云实验.png"),
      text: "云实验",
      path: "https://sy.ringdata.com/home",
    },
  ],
  [
    {
      img: require("@/assets/images/all-product/智社.png"),
      text: "智社",
      path: "https://www.smartsowo.com/",
    },
    // {
    //   img: require("@/assets/images/all-product/智小社.png"),
    //   text: "智小社",
    //   path: ""
    // },
    {
      img: require("@/assets/images/all-product/智慧蜂.png"),
      text: "智慧蜂",
      path: "https://www.zhihuifeng.com/",
    },
    {
      img: require("@/assets/images/all-product/创新计划.png"),
      text: "创新计划",
      path: "/rii",
    },
    {
      img: require("@/assets/images/all-product/循证联盟.png"),
      text: "循证联盟",
      path: "https://www.campbellchina.com/",
    },
  ],
];

const handleOpenAddEntityDrawer = () => {
  // console.log(handleOpenDrawer);
  handleOpenDrawer();
};

// 返回首页
const goSearch = () => {
  router.push("/search");
};

// 滚动条的显示
const scrollbarHeight = () => {
  if (state.searchCardList.length > 7) {
    return "500px";
  } else {
    document.querySelector(".el-scrollbar").removeAttribute("height");
  }
};

/**根据关键词查询推荐实体列表 */
const searchEntityByKeywords = async () => {
  if (!state.keywords) {
    state.cardVisible = false;
    return;
  } else {
    let knowledgeGraphId = window.localStorage.getItem(
      "knowledge-selectedGraph"
    );
    if (knowledgeGraphId == null) {
      remindErrorMessage("请选择图谱库");
      return;
    }
    state.cardVisible = true;
    state.searchCardList = [];
    let url = "/graph/v1/searchEntityByKeywords";
    let params = {
      knowledgeGraphId: knowledgeGraphId,
      keywords: state.keywords,
    };
    let res = await httpPost(url, params);
    if (res.code == 0) {
      state.searchCardList = res.data;
    }
    // console.log(state.searchCardList);
  }
};

/* 选中实体 */
const selectEntity = (name, entityId) => {
  // console.log("%%%%%");
  state.entityId = entityId;
  state.keywords = name;
  searchInputRef.value.focus();
  state.cardVisible = false;
  router.push({
    path: "./graph",
    query: { entityId: state.entityId, keywords: name },
  });
};

/* 查询实体 */
const searchByKeywords = async () => {
  let knowledgeGraphId = window.localStorage.getItem("knowledge-selectedGraph");
  if (knowledgeGraphId == null) {
    remindErrorMessage("请选择图谱库");
    return;
  }
  state.cardVisible = false;
  let url = "/graph/v1/searchEntityByKeywords";
  let params = {
    knowledgeGraphId: knowledgeGraphId,
    keywords: state.keywords,
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    state.searchCardList = res.data;
  }
  // console.log("222", state.searchCardList);
  if (state.searchCardList.length !== 0) {
    if (state.searchCardList[0].name === state.keywords) {
      state.entityId = state.searchCardList[0].entityId;
      router.push({
        path: "./graph",
        query: {
          entityId: state.entityId,
          keywords: state.searchCardList[0].name,
        },
      });
    } else {
      remindErrorMessage("暂无该实体");
    }
  } else {
    remindErrorMessage("暂无该实体");
  }
};
/**帮助 */
const goHelp = () => {
  window.open("https://help.ringdata.com/");
};
/**意见反馈 */
const goFeedBack = () => {
  window.open("");
};
/**前往产品 */
const goProduct = () => {
  window.open("");
};
/**前往产品大全 */
const goAllProduct = () => {
  window.open("");
};
watch(
  () => route,
  () => {
    reload();
  },
  { immediate: false, deep: true }
);

// TODO 临时改动
const tempOptions = ref([
  { value: "1", label: "乳腺癌" },
  { value: "2", label: "癌因性疲乏" },
  { value: "3", label: "乳腺癌淋巴水肿预防与干预" },
]);
let graph =
  window.localStorage.getItem("knowledge-selectedGraph") ||
  tempOptions.value[0].value;
const tempValue = ref(graph);
window.localStorage.setItem("knowledge-selectedGraph", tempValue.value);

const handleChangeTempValue = (value) => {
  window.localStorage.setItem("knowledge-selectedGraph", value);
};
</script>

<style lang="scss" scoped>
.layout-header {
  width: 100%;
  // height: 64px;
  line-height: 63px;
  display: flex;
  justify-content: space-between;
  // background-color: pink;
  border-bottom: 1px #d5d5d5 solid;
  .header-left {
    display: flex;
    align-items: center;
    .logo-wrapper {
      display: flex;
      align-items: center;
      .logo {
        height: 35px;
        cursor: pointer;
      }
      h1 {
        font-size: 24px;
        font-weight: normal;
        margin-left: 20px;
        color: #666;
      }
    }
    .search-wrapper {
      position: relative;
      margin-left: 20px;
      .el-input {
        width: 280px;
      }
      // 搜索推荐
      .el-card {
        position: absolute;
        top: 48px;
        left: 0px;
        width: 280px;
        padding-top: 13px;
        padding-bottom: 0;
        z-index: 999;

        :deep(.el-card__body) {
          padding: 0;
        }

        .search-item {
          display: flex;
          flex-direction: column;
          height: 70px;
          padding: 5px 13px 5px 13px;
          cursor: pointer;

          &:hover {
            background-color: #f4f9fd;
          }

          .title {
            flex: 1;
            display: flex;
            justify-content: space-between;

            h3 {
              color: #101010;
              font-size: 16px;
              line-height: 30px;
              font-weight: 400;
            }

            span {
              color: #101010;
              font-size: 14px;
              line-height: 30px;
            }
          }

          .description {
            width: 242px;
            flex: 1;
            line-height: 30px;
            align-self: flex-start;
            color: #777777;
            font-size: 12px;
            text-align: left;
          }
        }

        .notice {
          height: 70px;
          color: #777777;
          font-size: 14px;
          line-height: 70px;

          a {
            color: #3894ff;
          }
        }
      }
    }
  }
  .header-right {
    .el-button.is-circle.operate-btn {
      border: none;
      margin-left: 12px;
    }
    :deep .product-popover {
      padding: 0px 0px 0px 0px !important;
      .product-wrapper {
        height: 380px;
        overflow: auto;
        padding: 12px;
        text-align: center;
        .product-block {
          border-bottom: 1px solid #eee;
          display: flex;
          flex-wrap: wrap;
          .product {
            width: 60px;
            height: 70px;
            border-radius: 8px;
            font-size: 12px;
            padding: 6px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            img {
              width: 32px;
              height: 32px;
            }
            .text {
              width: 100%;
              line-height: 20px;
              text-align: center;
            }
          }
          .product:nth-child(3n) {
            margin-right: 0;
          }
          .product:hover {
            background-color: #eeeeee;
          }
          &:last-of-type {
            border-bottom: 0;
          }
        }

        // .btn-box {
        //   width: 100%;
        //   text-align: center;
        .el-button.more-product {
          color: #1a73e8;
          border-color: #bbbbbb;
          margin-top: 20px;
        }
        // }
      }
    }
  }
}
</style>
